// Custom Inputs

.custom-dropdown {
  max-height: $input-height;
  height: $input-height;
  min-height: $input-height;
  width: $input-width;
  font-size: $input-font-size;
  font-family: $input-typeface;
  padding: $input-padding-value;
  border-radius: $input-border-radius;
  background-color: $input-bg-color;
  color: $input-color;
  border-style: $input-border-style;
  border-width: $input-border-width;
  border-color: $input-border-color;
  box-shadow: none;

  &:focus, &:active {
    background-color: $input-bg-color;
    box-shadow: none;
    border: 0;
  }

  &.-lg {
    max-height: $input-height-lg;
    height: $input-height-lg;
    min-height: $input-height-lg;
    font-size: $input-font-size-lg;
  }

  &.-xlg {
    max-height: $input-height-xlg;
    height: $input-height-xlg;
    min-height: $input-height-xlg;
    font-size: $input-font-size-xlg;
  }
}

.custom-select {
  max-height: 40px;
  font-size: $input-font-size;
  font-family: $input-typeface;
  width: $input-width;
  padding: 0;
  border-radius: $input-border-radius;
  background-color: $input-bg-color;
  border-style: $input-border-style;
  border-width: $input-border-width;
  border-color: $input-border-color;

  input {
    height: auto !important;
    font-size: $input-font-size;
    padding: $input-padding;
    border-radius: $input-border-radius;
    background-color: transparent !important;
    border-style: $input-border-style;
    border-width: $input-border-width;
    border-color: $input-border-color;
    font-family: $input-typeface;
  }

  .custom-select__control {
    align-items: center;
    background-color: $input-bg-color !important;
    border-radius: $input-border-radius;
    border-style: $input-border-style;
    border-width: $input-border-width;
    border-color: transparent;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 40px;
    outline: 0 !important;
    position: relative;
    transition: all 100ms;
    box-sizing: border-box;
    box-shadow: none;

    &.custom-select__control--is-focused, &:hover, &:active {
      box-shadow: none;
      border-color: transparent;
    }

    .custom-select__value-container {
      align-items: center;
      display: grid;
      flex: 1;
      flex-wrap: wrap;
      padding: 2px 8px;
      -webkit-overflow-scrolling: touch;
      position: relative;
      overflow: hidden;
      box-sizing: border-box;

      .custom-select__placeholder {
        color: hsl(0, 0%, 50%);
        grid-area: 1/1/2/3;
        margin-left: 2px;
        margin-right: 2px;
        box-sizing: border-box;
      }

      .custom-select__input-container {
        margin: 2px;
        padding-bottom: 2px;
        padding-top: 2px;
        visibility: visible;
        color: hsl(0, 0%, 20%);
        flex: 1 1 auto;
        display: inline-grid;
        grid-area: 1/1/2/3;
        grid-template-columns: 0 min-content;
        box-sizing: border-box;

        .custom-select__input {
          color: inherit;
          background: 0 center;
          opacity: 1;
          width: 100%;
          grid-area: 1 / 2 / auto / auto;
          font: inherit;
          min-width: 2px;
          border: 0;
          margin: 0;
          outline: 0;
          padding: 0;
        }
      }
    }

    .custom-select__indicators {
      align-items: center;
      align-self: stretch;
      display: flex;
      flex-shrink: 0;
      box-sizing: border-box;

      .custom-select__indicator-separator {
        display: none;
        align-self: stretch;
        background-color: hsl(0, 0%, 80%);
        margin-bottom: 8px;
        margin-top: 8px;
        width: 1px;
        box-sizing: border-box;
      }

      .custom-select__indicator {
        color: hsl(0, 0%, 80%);
        display: flex;
        padding: 8px;
        transition: color 150ms;
        box-sizing: border-box;
      }
    }
  }

  .custom-select__menu {
    border: 0 solid transparent;
    box-shadow: $component-drop-shadow;
  }
}

.custom-input-button {
  max-height: $input-height;
  height: $input-height;
  min-height: $input-height;
  width: $input-width;
  font-size: $input-font-size;

  &.-lg {
    max-height: $input-height-lg;
    height: $input-height-lg;
    min-height: $input-height-lg;
    font-size: $input-font-size-lg;
  }

  &.-xlg {
    max-height: $input-height-xlg;
    height: $input-height-xlg;
    min-height: $input-height-xlg;
    font-size: $input-font-size-xlg;
  }
}

/// native select
.select-container {
  position: relative;
  color: $accentColor;
  background-color: $input-bg-color;
  border-radius: $input-border-radius;
  border-style: $input-border-style;
  border-width: $input-border-width;
  border-color: $input-border-color;

  select {
    position: relative;
    appearance: none;
    width: 100%;
    height: 40px;
    color: black;
    font-family: $body-font;
    font-size: 14px;
    padding: 4px 24px 4px 8px;
    border: 0;
    z-index: 5;
    background-color: transparent;
  }

  //noinspection CssNoGenericFontName
  &:after {
    position: absolute;
    top: 50%;
    right: 4px;
    font-size: 24px;
    margin-top: -12px;
    content: "\e92d";
    font-family: "Further-Iconset" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    z-index: 4;
  }
}

///input disabled design
.-input-disabled {
  background: repeating-linear-gradient(
          -45deg,
          rgba(0, 0, 0, 0.08),
          rgba(0, 0, 0, 0.08) 10px,
          $input-bg-color 10px,
          $input-bg-color 20px
  );
}

.bp3-input, .bp3-tag-input {
  font-family: $input-typeface;
  border-radius: $input-border-radius;
  background-color: $input-bg-color;
  color: $input-color;
  border-style: $input-border-style;
  border-width: $input-border-width;
  border-color: $input-border-color;
  box-shadow: none;

  &.bp3-active {
    background-color: unset !important;
    box-shadow: none !important;
  }
}
