@use "src/assets/style/variables";

.-errorPage {
  background: #f0f0f0 !important;
}

.errorBoundary {
  min-height: 70vh;

  @media screen and (max-width: 900px) {
    min-height: 85vh;
  }

  @media screen and (max-width: 600px) {
    min-height: 100vh;
  }

  .outer {
    position: relative;
    top: 100px;
    display: table;
    width: 100%;

    .middle {
      display: table-cell;
      vertical-align: middle;

      .inner {
        width: 300px;
        margin-right: auto;
        margin-left: auto;

        .inner-circle {
          height: 300px;
          border-radius: 50%;
          background-color: #ffffff;

          .bubble {
            font-size: 5em;
            line-height: 1em;
            float: right;
            width: 1.6em;
            height: 1.6em;
            margin-top: -.7em;
            margin-right: -.5em;
            padding: 20px;
            -webkit-transition: all .4s;
            transition: all .4s;
            text-align: center;
            color: #f5f5f5 !important;
            border-radius: 50%;
            background-color: variables.$secondaryColor;
            box-shadow: 0 0 0 15px #f0f0f0;

            svg {
              fill: variables.$primaryColor;
            }
          }

          span {
            font-size: 11em;
            font-weight: 700;
            line-height: 1.2em;
            display: block;
            -webkit-transition: all .4s;
            transition: all .4s;
            text-align: center;
            color: variables.$primaryColor;
          }

          &:hover {

            .bubble {
              color: variables.$primaryColor !important;
              background-color: #f5f5f5;
              box-shadow: 0 0 0 15px variables.$primaryColor;

              svg {
                fill: variables.$secondaryColor;
              }
            }

            span {
              color: variables.$secondaryColor;
            }
          }
        }

        .inner-status {
          font-size: 20px;
          display: block;
          margin-top: 20px;
          margin-bottom: 5px;
          text-align: center;
          color: variables.$primaryColor;
        }

        .inner-detail {
          line-height: 1.4em;
          display: block;
          margin-bottom: 10px;
          text-align: center;
          color: #777;
        }
      }

      .bottom {
      }
    }
  }
}
