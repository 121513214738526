@font-face {
  font-family: 'Further-Iconset';
  src:  url('fonts/Further-Iconset.eot?dqi7tp');
  src:  url('fonts/Further-Iconset.eot?dqi7tp#iefix') format('embedded-opentype'),
    url('fonts/Further-Iconset.ttf?dqi7tp') format('truetype'),
    url('fonts/Further-Iconset.woff?dqi7tp') format('woff'),
    url('fonts/Further-Iconset.svg?dqi7tp#Further-Iconset') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="fi-"], [class*=" fi-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Further-Iconset' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fi-plane-outline:before {
  content: "\e938";
}
.fi-inc_ftf:before {
  content: "\e938";
}
.fi-clock-outline:before {
  content: "\e940";
}
.fi-7_24:before {
  content: "\e940";
}
.fi-pin-outline:before {
  content: "\e941";
}
.fi-airport:before {
  content: "\e941";
}
.fi-city_center:before {
  content: "\e941";
}
.fi-wallet-outline:before {
  content: "\e942";
}
.fi-pay_lab:before {
  content: "\e942";
}
.fi-calendar-circle:before {
  content: "\e937";
}
.fi-weekend:before {
  content: "\e937";
}
.fi-check-outline:before {
  content: "\e936";
}
.fi-preferred:before {
  content: "\e936";
}
.fi-cross-circle-outline:before {
  content: "\e943";
}
.fi-free_cancel:before {
  content: "\e943";
}
.fi-fast-up:before {
  content: "\e939";
}
.fi-high_demand:before {
  content: "\e939";
}
.fi-spur-outline:before {
  content: "\e93a";
}
.fi-passport-further:before {
  content: "\e93b";
}
.fi-fp:before {
  content: "\e93b";
}
.fi-official:before {
  content: "\e93b";
}
.fi-passport:before {
  content: "\e93c";
}
.fi-aup:before {
  content: "\e93c";
}
.fi-eudcc:before {
  content: "\e93c";
}
.fi-cp:before {
  content: "\e93c";
}
.fi-phone-booking:before {
  content: "\e93d";
}
.fi-online_book:before {
  content: "\e93d";
}
.fi-speech-bubble:before {
  content: "\e93e";
}
.fi-english:before {
  content: "\e93e";
}
.fi-turkish:before {
  content: "\e93e";
}
.fi-arabic:before {
  content: "\e93e";
}
.fi-tag-star:before {
  content: "\e93f";
}
.fi-best_price:before {
  content: "\e93f";
}
.fi-arrow:before {
  content: "\e91f";
}
.fi-close:before {
  content: "\e920";
}
.fi-logo-symbol:before {
  content: "\e922";
}
.fi-logo-horizontal:before {
  content: "\e910";
}
.fi-logo-further:before {
  content: "\e911";
}
.fi-plane-on-the-move:before {
  content: "\e90f";
}
.fi-arrival-test:before {
  content: "\e912";
}
.fi-departure-test:before {
  content: "\e913";
}
.fi-info:before {
  content: "\e905";
}
.fi-warning:before {
  content: "\e90e";
}
.fi-calendar-plane:before {
  content: "\e900";
}
.fi-calendar:before {
  content: "\e91d";
}
.fi-plane:before {
  content: "\e909";
}
.fi-landing:before {
  content: "\e923";
}
.fi-takeoff:before {
  content: "\e924";
}
.fi-earth:before {
  content: "\e902";
}
.fi-earth-small:before {
  content: "\e925";
}
.fi-go-in:before {
  content: "\e903";
}
.fi-guide:before {
  content: "\e904";
}
.fi-magnifier:before {
  content: "\e906";
}
.fi-move:before {
  content: "\e907";
}
.fi-padlock:before {
  content: "\e908";
}
.fi-share-qr:before {
  content: "\e90a";
}
.fi-shield-check:before {
  content: "\e90b";
}
.fi-clinic:before {
  content: "\e931";
}
.fi-test:before {
  content: "\e90c";
}
.fi-wallet:before {
  content: "\e90d";
}
.fi-no-pass:before {
  content: "\e921";
}
.fi-clock:before {
  content: "\e901";
}
.fi-location:before {
  content: "\e914";
}
.fi-check-circle:before {
  content: "\e91e";
}
.fi-check:before {
  content: "\e917";
}
.fi-check-single:before {
  content: "\e918";
}
.fi-chevron-circle-left:before {
  content: "\e91a";
}
.fi-chevron-circle-down:before {
  content: "\e929";
}
.fi-chevron-circle-right:before {
  content: "\e92a";
}
.fi-chevron-circle-up:before {
  content: "\e92b";
}
.fi-chevron-circle:before {
  content: "\e92c";
}
.fi-chevron-left:before {
  content: "\e91b";
}
.fi-chevron-down:before {
  content: "\e92d";
}
.fi-chevron-right:before {
  content: "\e92e";
}
.fi-chevron-up:before {
  content: "\e92f";
}
.fi-chevron:before {
  content: "\e930";
}
.fi-cross-circle:before {
  content: "\e926";
}
.fi-stop-small:before {
  content: "\e927";
}
.fi-warning-small:before {
  content: "\e928";
}
.fi-waiting:before {
  content: "\e919";
}
.fi-waiting-alt:before {
  content: "\e91c";
}
.fi-user:before {
  content: "\e932";
}
.fi-phone:before {
  content: "\e933";
}
.fi-phone-circle:before {
  content: "\e934";
}
.fi-mail:before {
  content: "\e935";
}
.fi-store-google:before {
  content: "\e915";
}
.fi-store-apple:before {
  content: "\e916";
}
