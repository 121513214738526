// Variables

$accentColor: #6466AC;
$primaryColor: $accentColor;
$hoverColor: lighten($accentColor, 10%);
$secondaryColor: #00ffac;
$mandatory-color: #df0000;
$warning-color: orange;
$alertColor: #ffbe07;
$confirmColor: #00ffac;
$exceptional-color: #df0000;
$activeTextColor: #fff;
$header-height: 56px;
$border-lightColor: rgba(0, 0, 0, 0.1);
//$body-font: "Nunito Sans";
$body-font: "Inter";
$header-font: "Sora";
$unsetTestColor: #ccc;
$setTestColor: $confirmColor;
$component-drop-shadow: 0 5px 30px 0 rgb(0 0 0 / 15%);
$flight-header-bgcolor: white;

/// input variables
$input-height: 40px;
$input-width: 100%;
$input-bg-color: #e9ecef;
$input-color: $accentColor;
$input-typeface: $body-font, sans-serif !important;

$input-font-size: 14px;
$input-font-size-lg: 16px;
$input-font-size-xlg: 20px;

$input-padding-top: 6px;
$input-padding-right: 36px;
$input-padding-bottom: 6px;
$input-padding-left: 12px;

$input-padding-value: $input-padding-top $input-padding-right $input-padding-bottom $input-padding-left;
$input-height: ($input-padding-top * 2) + ($input-padding-bottom * 2) + $input-font-size;
$input-height-lg: ($input-padding-top * 2) + ($input-padding-bottom * 2) + $input-font-size-lg;
$input-height-xlg: ($input-padding-top * 2) + ($input-padding-bottom * 2) + $input-font-size-xlg;
$input-border-style: solid;
$input-border-width: 0 0 1px 0;
$input-border-color: transparent transparent transparent transparent;
$input-border-radius: 4px;
$input-padding: 6px 8px;

$address-label-font-size: 12px;
$address-label-color: #808080;

$info-box-header-font-size: $address-label-font-size;
$info-box-header-color: $address-label-color;
