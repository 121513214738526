$fp--grid-columns: 12;
$fp--gutter-width: 12px;

$fp--breakpoints: (
  'md': 769px,
  'lg': 1024px
);
$fp--container: (
  'md': 960px,
  'lg': 1320px
);
*{
    box-sizing: border-box;
}
.fp-container-fluid{
    position: relative;
    width: 100%;
}
.fp-container {
    width: 100%;
    margin: 0 auto;
    padding: 0 $fp--gutter-width;
    @each $breakpoint, $width in $fp--container {
        @media (min-width: map-get($fp--container, $breakpoint)) {
          max-width: $width;
        }
    }
}
.fp-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$fp--gutter-width;
  margin-right: -$fp--gutter-width;
}
[class*="cell-"] {
  // flex-grow: 1;
  padding-inline: 0.5rem;
}
@for $i from 1 through $fp--grid-columns {
    .cell-#{$i} {
        width: calc(100% / #{$fp--grid-columns} * #{$i});
    }
}
@each $breakpoint, $value in $fp--breakpoints {
    @media (min-width: $value) {
        @for $i from 1 through $fp--grid-columns {
            .cell-#{$breakpoint}-#{$i} {
                width: calc(100% / #{$fp--grid-columns} * #{$i});
            }
        }
    }
}

$fp-responsive: (
  'mobile': (0px, 768px),
  'tablet': (0px, 1023px),
  'desktop': (1024px, null)
);

@mixin respond($breakpoint) {
  $range: map-get($fp-responsive, $breakpoint);
  
  @if $range {
    $min: nth($range, 1);
    $max: nth($range, 2);

    @if $max {
      @media (min-width: $min) and (max-width: $max) {
        @content;
      }
    } @else {
      @media (min-width: $min) {
        @content;
      }
    }
  } @else {
    @warn "Unknown breakpoint: #{$breakpoint}.";
  }
}
