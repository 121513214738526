// Static Content

.-jumbotron {
  color: black;
  flex-direction: column;
  background-color: $accentColor;
  background-size: cover;

  .-headerLogo {
    @media (max-width: 768px) {
      height: 80px;
    }
    margin-top: 0;
    height: 100px;
    margin-bottom: 56px;
  }

  h1, h2, h3 {
    font-weight: 400;
    font-family: $body-font, sans-serif !important;
  }

  h1, h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 18px;
  }
}

.-headerMessage {
  padding: 56px 0;
  color: $accentColor;
  background-color: lighten($accentColor, 45%);

  .-cta {
    font-size: 32px;
    padding: 0;
    margin: 0 0 8px 0;
  }

  .-message {
    font-size: 18px;
  }
}

.-footer {
  background-color: lighten($primaryColor, 40%);
  color: black;
  padding: 60px 0 0 0;
  font-size: 12px;

  a {
    color: black;
    text-decoration: none;
  }

  .StarAllianceLogo {
    width: 280px;
  }

  .-logoContainer {
    margin-bottom: 30px;
  }

  .-footerLogo {
    font-size: 64px;
  }

  .-footerFurtherLogoContainer {
    display: flex;
    align-items: flex-end;

    a {
      display: flex;
      align-items: flex-end;

      .-footerFurtherLogo {
        margin-top: 20px;
        font-size: 34px;
      }
    }
  }

  .-contactInfo {
    padding: 10px;

    h3 {
      position: relative;
      float: left;
      font-weight: 400;
      margin: 0 20px 20px 0;
      font-size: 18px;
    }

    p {
      position: relative;
      float: left;
      margin: 0;
    }
  }

  .-storeLinks {
    .badges {
      position: relative;
      float: left;
      font-size: 48px;
      margin-right: 20px;
    }

    h4 {
      margin: 0;
      position: relative;
      float: left;
      font-size: 18px;
    }
  }

  .-disclaimer {
    font-size: 12px;
    margin-top: 30px;

    a {
      position: relative;
      float: left;
      padding-right: 25px;
      padding-bottom: 10px;
      font-size: 16px;
    }

    .-textDisclaimer, .-address {
      position: relative;
      float: left;
      width: 100%;
      line-height: 18px;
    }

    .-address {
      font-size: 12px;
    }
  }
}
