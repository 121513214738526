@use "fp-grid" as *;

// The Style Sheet
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;500&family=Sora:wght@300;500&display=swap");
@import url("/src/assets/iconset/style.css");

@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "variables";
@import "overrides";
@import "custom-inputs";
@import "custom-stripe";
@import "universal-components";
@import "static-content";
@import "app-promotion";

.-flip {
  transform: scaleX(-1);
}

.-underline-text {
  text-decoration: underline;
}

.-rotate90 {
  transform: rotate(90deg);
}

.-rotate45 {
  transform: rotate(45deg);
}

.-box-shadow {
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.15);
}

.-action-shadow {
  box-shadow: 0 5px 30px -10px $accentColor;
}

.-action {
  @extend .-action-shadow;
}

a {
  color: $primaryColor;
}

a:hover {
  color: $hoverColor;
}

body {
  font-family: $body-font, sans-serif !important;
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 1.2;
}

// FIXME this is to distinguish
.new-design {
  .container {
    // max-width: 1320px;
    max-width: 1920px;
    @media screen and (max-width: 1024px) {
      max-width: 540px;
    }
  }
}

.accordion-button:not(.collapsed)::after {
  // background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .-cookieContainer {
    justify-content: center !important;
    align-items: center !important;

    a {
      color: white;
      text-decoration: underline;
    }
  }

  .-cookieText {
    // display: grid;
    justify-content: center;
    align-items: center;
    max-width: max-content;
    margin-left: 1rem;
  }

  .-cookieButton {
    margin-right: 10rem !important;
    color: #000 !important;
    font-size: 15px !important;
    background: #fff !important;
    border-radius: 3px !important;
    border: 0;
  }

  .-cookieButton:hover {
    background: #ccc !important;
  }
}

[type=reset], [type=submit], button, html [type=button] {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent; /* for removing the highlight */
}

@keyframes pulse-logo {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  0% {
    opacity: 0;
  }
}

.overlay {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  flex-grow: 2;
  backdrop-filter: blur(20px);
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.8);

  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      height: 80px;
      opacity: 1;
      animation: pulse-logo 0.75s ease-in-out infinite;
    }
  }
}

.-errorPage {
  display: flex;
  flex-grow: 2;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  @media (max-width: 768px) {
    min-height: 150px;
  }

  .container {
    text-align: center;
  }
}

.-countriesList {
  min-height: 50vh;
  padding-top: 50px;


  .-countriesList-selection {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .container {
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }

  .big-select {
    font-size: 1.5em;
  }
}

nav {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  // width: 100%;
  font-family: "Sora", sans-serif;
  z-index: 10;
  padding: 1rem !important;
  transition: all 0.5s ease;
  .navbar-logo{
    font-size: 100px;
    transition: all 0.3s;
    @include respond(tablet) {
      font-size: 36px;
    }
  }
  .navbar-brand {
    padding: 0;
    margin: 0;

    img {
      width: 200px;
      transition: all 0.5s ease;
      @media screen and (max-width: 1024px) {
        width: 120px;
      }
    }
  }

  .break {
    flex-basis: 100% !important;
    width: 0;
    height: 0;
    padding: 0 !important;
    margin: 0 !important;
  }

  .break.show {
    border-top: 1px solid whitesmoke;
  }

  .active {
    font-weight: 500;
  }

  .navbar-collapse {
    @media screen and (max-width: 1024px) {
      padding: 0.5rem;
      margin-top: 0.5rem;
      z-index: 50;
    }
  }
}

.navbar {
  .container {
    flex-wrap: wrap !important;
  }
}

.sub-bar {
  background-color: #585b78;
  font-family: "Sora", sans-serif;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.8) !important;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 1) !important;
}

.main {
  $newMenuMargin: 113px;
  $newMenuMargin1024: 158px;
  $newMenuMargin991: 74px;

  margin-top: $newMenuMargin;

  &.-legalPages {
    margin-top: $newMenuMargin + 20px;
  }

  @media (max-width: 1024px) {
    margin-top: $newMenuMargin1024;
    &.-legalPages {
      margin-top: $newMenuMargin1024 + 20px;
    }
  }

  @media (max-width: 991px) {
    margin-top: $newMenuMargin991;
    &.-legalPages {
      margin-top: $newMenuMargin991 + 20px;
    }
  }
}

.title-pane {
  .-sectionTitle {
    font-size: 24px;
  }
}

.scrolled {
  background-color: #61678e !important;
  // background-color: #1d1e2f !important;
  transition: all 0.5s ease;

  .nav-link, .active {
    color: white;

    &.active {
      color: white;
    }
  }

  .navbar-brand {
    .navbar-logo{
      font-size: 36px;
    }
    img {
      width: 100px;
      transition: all 0.5s ease;
    }
  }
}

.-langSelector {
  display: flex;
  flex-direction: row;

  a {
    border-right: 1px solid rgba(255, 255, 255, 0.1) !important;
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;

    &:last-of-type {
      border-right: 0 !important;
    }
  }

  .-active {
    font-weight: bold;
    color: rgba(255, 255, 255, 0.8) !important;
  }
}

.-mainSelection {
  background-color: lighten($accentColor, 45%);

  .-findClinics, .-checkReg {
    min-height: 400px;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    padding: 0 0 40px 0;
    align-items: flex-end;

    .btn {
      @extend .-action-shadow;
    }
  }

  .-findClinics {
    background-image: url(../img/main-find-clinics.svg);
  }

  .-checkReg {
    background-image: url(../img/main-check-reg.svg);
  }
}

.-flightFormContainer {
  padding: 30px 0;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.-mainSubmitSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.-mainSubmitButton {
  button {
    font-size: 18px;
    @extend .-action-shadow;
  }
}

.-passengerInfo {

  .row {
    margin-bottom: 0 !important;
  }

  .card {
    border: 0;
    border-radius: 4px;
    background-color: #f7f7f7;
    // box-shadow: 0 5px 25px -20px black;
    .card-body {
      padding: 12px;
    }
  }
}

.-travelInfo {
  .row {
    margin-bottom: 0 !important;
  }

  .-flightContainer {
    .accordion-item {

      .-locationLabel {
        margin: 10px 0;
        padding: 0;
        font-size: 16px;
      }

      .list-group {
        position: relative;
        float: left;
        width: 100%;

        .list-group-item {
          padding: 6px 0;
          margin: 0;
          border: 0;
        }
      }
    }
  }
}

.-summaryInfo {
  .container {
    border-bottom: 1px solid $border-lightColor;
  }

  .-products {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    border-right: 1px solid $border-lightColor;
  }

  .-paymentPane {
    .form-check {
      margin-bottom: 0 !important;
    }

    margin-top: 0 !important;
    margin-bottom: 0 !important;

    .-totalPrice-row {
      border-bottom: 1px solid $border-lightColor;
    }

    .-payableRow {
      border-bottom: 1px solid $border-lightColor;
    }

    .-totalAmount {
      margin: 6px 0;
      text-align: right;
    }

    .-otherPaymentOptions {
      margin-bottom: 6px;
    }

    .-differentID-selection-row {
      border-bottom: 1px solid $border-lightColor;
      border-top: 1px solid $border-lightColor;

      .col-12 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }
    }

    .-differentID-row {
      .-differentID-col {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }

    }

    .-payAction {
      margin-top: 20px;
      margin-bottom: 20px;

      .-acceptedCardsContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 20px;
        position: relative;
        width: 100%;

        .-secureMessages {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          border-bottom: 1px solid $border-lightColor;
          padding: 8px 0;

          img {
            height: 38px;
            margin: 4px;
          }
        }

        .-acceptedCards {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          padding: 8px 0;
          border-bottom: 1px solid $border-lightColor;

          .-logoContainer {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 40px;
              margin: 4px;
            }
          }
        }

        span {
          font-size: 32px;
          color: #0dcaf0;
        }
      }
    }

    .-totalPrice {
      label, input {
        text-align: right;
      }

      label {
        width: 100%;
      }

      input {
        font-size: 26px;
        padding: 0;
      }
    }

    .-blackLabel {
      color: #666;
    }
  }
}

.-paymentInfo {

  .container {
    padding-top: 50px;
    padding-bottom: 80px;
    // border-top: 1px solid $border-lightColor;
  }

}

.-container-fix {
  flex-grow: 2;
  margin: 50px 0;
  display: flex;
}

.-flightInfo-main, .-container-fix {
  .-icon {
    color: $primaryColor;
    font-size: 32px;
    min-width: 32px;
    float: left;
    display: block;
  }

  .-calendarReturn {
    transform: scaleX(-1);
  }

  label:not(.btn) {
    font-size: 14px;
    margin-top: 6px;
    margin-bottom: 6px;
    color: $accentColor;
  }

  .mandatoryLabel {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 20px;
      height: 2px;
      background-color: $mandatory-color;
    }
  }

  .mandatoryField {
    &:after {
      content: "";
      position: absolute;
      bottom: 2px;
      left: 4px;
      right: 4px;
      height: 3px;
      background-color: $mandatory-color;
    }
  }

  p {
    position: relative;
    //margin: 0;
  }

  .infoArea {
    padding: 4px;
    background-color: lighten($mandatory-color, 50%);
    border-bottom: 4px solid $mandatory-color;

    p {
      padding: 0;
      margin: 0;
    }
  }

  .warningArea {
    padding: 4px;
    background-color: $warning-color;
    font-weight: 400;
    // border-bottom: 4px solid $mandatory-color;
    p {
      padding: 0;
      margin: 0;
    }
  }

  .form-check {
    padding-left: 0;
  }

  .custom-select:disabled, .custom-dropdown:disabled {
    @extend .-input-disabled;
  }

  .custom-select {
    // height: 40px;


    input:disabled {
      @extend .-input-disabled;
    }

    .text {
      line-height: 28px !important;
    }

    .divider {
      font-size: 14px !important;
    }
  }

  .inputLabelContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 4px;

    .-additionalFieldLabel {
      font-size: 14px;
    }

    label {
      display: flex;
      flex-direction: column;
      flex-grow: 2;
      justify-content: center;
      margin-left: 4px;

      .subText {
        font-size: 14px;
      }
    }
  }

  input, textarea {
    font-size: $input-font-size;
    padding: $input-padding;
    border-radius: $input-border-radius;
    background-color: $input-bg-color;
    border-style: $input-border-style;
    border-width: $input-border-width;
    border-color: $input-border-color;
    font-family: $input-typeface;
  }

  input {
    height: $input-height;
    width: $input-width;
  }
}

.fieldError {
  @media (max-width: 768px) {
    font-size: 12px;
  }
  position: relative;
  float: left;
  width: 100%;
  color: red;
  padding: 6px 8px;
  font-size: 14px;
}

.-flightInfo-main, .-flightInfo-summary {
  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }

  > .container {
    @media (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;
    }

    > .row {
      @media (max-width: 768px) {
        margin: 0;
      }
    }
  }

  .-flightInfo-container {
    @media (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.-flightInfo-main {
  padding-top: 30px;

  label:not(.btn) {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .-label-wIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .-flight-form {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 0 6px 6px 6px;
    // margin-bottom: 100px;

    .row {
      margin-left: 0;
      margin-right: 0;
      width: 100%;

      .-flight-form-col {
        padding: 0 4px;
      }
    }

    .col-1 {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }

    .form-check, .form-check-inline {
      margin-bottom: 0;
      min-height: auto;
    }

    .-icon {
      font-size: 20px;
      min-width: 20px;
      max-width: 20px;
    }

    .dOzCYr, .KmfpE {
      width: 100%;
    }

    .-flight-form-title {
      color: $primaryColor;
      padding: 10px;
      font-size: 18px;
    }

    .-connectedFrame {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        height: 20px;
        // width: 1px;
        top: -24px;
        bottom: 0;
        right: 0;
        left: 0;
        border-style: solid;
        border-color: $accentColor;
        border-width: 1px 0 0 1px;
        // border: 1px dashed $accentColor;
        // border-right: 0;
        border-radius: 4px 0 0 0;
        z-index: 0;
      }
    }

    .-connectedField {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 30px;

      .-connectToTop {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          left: 50%;
          bottom: 90px;
          width: 1px;
          height: 80%;
          background-color: $accentColor;
          z-index: 1;
        }

        .-icon {
          background-color: $accentColor;
          color: white;
          border-radius: 50%;
          min-width: 26px;
          max-width: 26px;
          height: 26px;
          justify-content: center;
          align-items: center;
          display: flex;
          margin-top: -3px;
          z-index: 10;
        }
      }
    }

    .-footerNav {
      margin-bottom: 0;

      .row {
        margin-top: 0;

        button {
          margin-top: 0;
          width: 100%;
        }
      }
    }

    .-message-col {
      padding-top: 4px !important;
      padding-bottom: 4px !important;
      border-bottom: 1px solid $border-lightColor;
    }

    .-regulationMessage {
      font-size: 14px;
    }
  }
}

.-flightInfo-main, .-container-fix {
  input:disabled {
    background-color: #fdfdfd !important;
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked),
  [type="checkbox"]:checked,
  [type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type="radio"]:checked + label:not(.btn),
  [type="radio"]:not(:checked) + label:not(.btn),
  [type="checkbox"]:checked + label:not(.btn),
  [type="checkbox"]:not(:checked) + label:not(.btn) {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
  }

  [type="radio"]:checked + label:not(.btn):before,
  [type="radio"]:not(:checked) + label:not(.btn):before,
  [type="checkbox"]:checked + label:not(.btn):before,
  [type="checkbox"]:not(:checked) + label:not(.btn):before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    background: transparent;
  }

  [type="radio"]:checked + label:not(.btn):before,
  [type="radio"]:not(:checked) + label:not(.btn):before {
    border-radius: 100%;
    border: 2px solid $primaryColor;
  }

  [type="checkbox"]:checked + label:not(.btn):before,
  [type="checkbox"]:not(:checked) + label:not(.btn):before {
    border-radius: 2px;
    border: 2px solid $primaryColor;
  }

  [type="radio"]:checked + label:not(.btn):after,
  [type="radio"]:not(:checked) + label:not(.btn):after,
  [type="checkbox"]:checked + label:not(.btn):after,
  [type="checkbox"]:not(:checked) + label:not(.btn):after {
    content: "";
    width: 18px;
    height: 18px;
    background: $accentColor;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.2s ease;
  }

  [type="radio"]:checked + label:not(.btn):after,
  [type="radio"]:not(:checked) + label:not(.btn):after {
    border-radius: 100%;
  }

  [type="checkbox"]:checked + label:not(.btn):after,
  [type="checkbox"]:not(:checked) + label:not(.btn):after {
    border-radius: 4px;
  }

  [type="radio"]:not(:checked) + label:not(.btn):after,
  [type="checkbox"]:not(:checked) + label:not(.btn):after {
    opacity: 0;
    transform: scale(0);
  }

  [type="radio"]:checked + label:not(.btn):after,
  [type="checkbox"]:checked + label:not(.btn):after {
    opacity: 1;
    transform: scale(1);
  }
}

.-header {
  background-color: #eee;
  padding: 40px 0;
  margin-bottom: 50px;
}

.-how {
  @media (max-width: 768px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  background-color: lighten($accentColor, 45%);
  padding-top: 150px;
  padding-bottom: 150px;

  h2 {
    font-size: 26px;
    padding-bottom: 50px;
  }

  p {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 16px;
  }

  .-step {
    position: relative;

    &:last-of-type {
      &:before, &:after {
        display: none;
      }
    }

    &:before, &:after {
      @media (max-width: 768px) {
        display: none;
      }
      content: "";
      position: absolute;
      border-style: solid;
      display: none;
      /// removed arrow masks
    }

    &:before {
      top: 0;
      right: 12px;
      border-width: 0 20px 100px 0;
      border-color: transparent lighten($accentColor, 45%) transparent transparent;
    }

    &:after {
      top: 100px;
      right: 12px;
      border-width: 0 0 100px 20px;
      border-color: transparent transparent lighten($accentColor, 45%) transparent;
    }
  }

  .-slide {
    background-size: cover;
    height: 200px;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;

    &.-create {
      background-image: url(../img/slide-search.jpg);
    }

    &.-guide {
      background-image: url(../img/slide-create.jpg);
    }

    &.-deliver {
      background-image: url(../img/slide-verify.jpg);
    }

    &.-check {
      background-image: url(../img/slide-share.jpg);
    }
  }

  .-icon {
    color: white;
    font-size: 64px;
  }
}

.-preferredCountriesContainer {
  @media (max-width: 768px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  background-color: lighten($accentColor, 43%);
  padding-top: 150px;
  padding-bottom: 150px;

  h2 {
    font-size: 26px;
    padding-bottom: 50px;
  }

  .-preferredCountries {
    .-countryButton {
      margin-bottom: 28px;

      .btn {
        transition: all 0.5s ease;
        width: 100%;

        &:hover {
          @extend .-action-shadow;
        }
      }
    }

    @media (max-width: 950px) {
      // width: 100%;
      // display: flex;
      // .col-3{
      //   display: inline-table!important;
      //   flex-wrap: wrap;
      //   overflow-y: revert;
      // }
    }

  }

}

.-benefits {
  margin-top: 100px;
  margin-bottom: 100px;
  text-align: center;

  .-icon {
    font-size: 64px;
    color: $primaryColor;
    display: inline-block;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.-reg-info-table {
  .-info-box {
    @media (max-width: 768px) {
      // padding-left: 0;
      // padding-right: 0;
    }

    a {
      color: $accentColor;
      text-decoration: none;
    }

    .-header {
      padding: 0;
      margin: 0;
      width: 100%;
      color: $info-box-header-color;
      background-color: white;
      font-weight: 700;
      font-size: $info-box-header-font-size;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border-bottom: 1px solid $border-lightColor;
    }

    .-body {
      padding: 0;
      margin: 0;
      font-size: 14px;

      ul {
        margin: 0;
        padding: 0 0 0 18px;

        li {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}

.-travelInfoContainer {
  .-travelHeader {
    display: flex;
    flex-direction: row;
    height: 40px;
    background-color: #f2f2f2;

    .-travelHeader-title {
      display: flex;
      flex-grow: 2;
      align-items: center;
      height: 40px;
      padding: 8px;
      font-size: 18px;
    }

    .-travelHeader-button {
      border-radius: 0;
      border: 0;
      width: 50px;
      height: 40px;
      background-color: #eaeaea;
      padding: 16px;
    }
  }
}

.-location {
  font-size: 18px;
}

.-travelDateTime {
  color: #415bb3;
  font-size: 15px;
}

.-flightContainer {
  .-currencySelection {
    display: flex;
    flex-direction: row;
    padding: 0 4px 8px 0;
    margin-bottom: 8px;
    border-bottom: 1px solid $border-lightColor;

    .-label {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-grow: 2;
      font-size: 14px;
      padding: 0 8px;
    }

    .select-container {
      width: 100px;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      padding: 0;
      font-size: 14px;
    }
  }

  > .accordion-item {
    @media (max-width: 768px) {
      border-bottom: 1px solid $border-lightColor;
    }
    margin-bottom: 20px;
    border: 0;

    &:last-of-type {
      border-radius: 0;
    }

    > .-contentContainer {
      border-top: 1px solid $border-lightColor;
    }
  }

  .accordion-item {
    // border:1px solid $border-lightColor;
    // padding:8px;
    border-bottom: 1px solid $border-lightColor;

  }

  .-flightHeader {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $flight-header-bgcolor;

    &:before {
      // content: "";
      position: absolute;
      bottom: 50%;
      // width:100%;
      left: 100px;
      right: 100px;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.1);
    }

    .-indicator {
      position: relative;
      min-width: 30px;
      width: 30px;
      min-height: 30px;
      height: 30px;
      margin: 10px;
      border-radius: 50%;

      &.hold {
        background-color: #ccc;
      }

      &.done {
        background-color: $confirmColor;
      }
    }

    .-flightHeader-title {
      @media (max-width: 768px) {
        font-size: 16px;
        height: auto;
        padding: 8px 0 8px 0;
      }
      display: flex;
      flex-grow: 2;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      padding: 8px;
      font-size: 22px;
      cursor: pointer;
      color: $accentColor;

      .-flightTime {
        @media (max-width: 768px) {
          font-size: 14px;
          height: 50px;
          align-items: center;
        }
        font-size: 18px;
        display: flex;
        justify-content: center;
        flex-grow: 2;
        text-align: center;

        p {
          margin: 0;
          padding: 0 8px;
          background-color: $flight-header-bgcolor;
        }
      }

      .-origin, .-destination {
        @media (max-width: 768px) {
          font-size: 16px;
          flex-direction: column;
          min-width: 50px;
          width: 50px;
          align-items: center !important;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
        min-width: 100px;
        background-color: $flight-header-bgcolor;
        z-index: 5;

        .-label-iconContainer {
          @media (max-width: 768px) {
            font-size: 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          display: flex;
          flex-direction: row;

          .-label, .-icon {
            display: flex;
          }

          .-label {
            margin: 0;
            padding: 0 8px;
            background-color: $flight-header-bgcolor;
            align-items: center;
          }

          .-icon {
            @media (max-width: 768px) {
              font-size: 22px;
              width: 22px;
              min-width: 22px;
              height: 22px;
            }
          }
        }

        .-text {
          font-size: 14px;
        }
      }

      .-origin {
        align-items: flex-start;
      }

      .-destination {
        align-items: flex-end;
      }
    }

    .-flightHeader-button {
      border-radius: 0;
      border: 0;
      width: 50px;
      height: 50px;
      background-color: transparent;
      padding: 16px;
      box-shadow: none;
    }
  }

  .-skipSelection {
    height: 38px;
    margin: 20px 0;
    background-color: #f2f2f2;
    padding: 8px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .form-check {
      margin: 0;
    }

    input {
      display: flex;
      border-radius: 0;
      border: 0;
      background-color: #555;
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    label {
      display: flex;
      line-height: 20px;
      font-size: 16px;
    }

    input:checked {
      background-color: $confirmColor;
    }
  }

  .-labTitle {
    height: 50px;
    background-color: $confirmColor;
    padding: 16px;
  }

  .-mapContainer {
    padding: 0;
    border-radius: 4px;
    overflow: hidden;

    div {
      position: relative;
      height: 100%;
    }

  }

  .-labInfo {
    position: relative;
    padding: 0 !important;
    display: flex;
    flex-direction: column;

    .map {
      position: relative;
      float: left;
      width: 100%;
      height: 300px;
    }

    .-labInfoDetails {
      li {
        @media (max-width: 768px) {
          padding: 4px;
        }
        position: relative;
        float: left;
        width: 100%;
        margin: 0;
        padding: 8px;
        list-style: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);

        div {
          position: relative;
          float: left;
        }
      }

      .-labAvailableTestsList {
        margin: 0;
        padding: 0;
        list-style: none;
      }
    }

    .-disclaimer {
      width: 100%;
      position: relative;
      float: left;
      padding: 8px;
      margin: 0;
      display: flex;
      flex-direction: row;
      font-size: 10px;
      color: black;

      .-icon {
        font-size: 24px;
        color: $warning-color;
        margin: 0 8px 0 0;
      }
    }
  }

  .-notSelectedLab {
    display: none;
  }

  .-labList {
    margin-bottom: 0;

    .tab-content {
      padding: 0;
    }

    .accordion-item {
      padding: 0 0 8px 0;
      margin: 0 0 8px 0 !important;
      border-radius: 0;
      position: relative;
      float: left;
      width: 100%;
      border: none;
      border-bottom: 1px solid $border-lightColor;
      overflow: hidden;

      .accordion-header {
        .accordion-button {
          @media (max-width: 768px) {
            padding: 0;
          }
          border: 0;
          margin: 0 0;
          padding: 4px;
          background-color: transparent;
        }

        .-testDetails {
          position: relative;
          float: left;
          width: 100%;
          display: flex;
          flex-direction: row;
          padding: 0;

          .-icon {
            font-size: 24px;
            margin: 0 0 0 0;
          }

          .-labName {
            @media (max-width: 768px) {
              width: 100%;
              padding: 4px 4px 4px 36px;
            }
            width: 50%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding: 4px 4px 4px 24px;
            font-weight: 700;
            font-size: 14px;
            display: flex;
            align-items: center;

            p {
              width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          .-testType, .-testPrice, .-testLocation {
            @media (max-width: 768px) {
              padding: 4px;
            }
            position: relative;
            float: left;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: 4px;

            p {
            }
          }

          .-testType {
            font-size: 14px;
          }

          .-testPrice {
            @media (max-width: 768px) {
              padding: 4px 4px 4px 36px;
            }
            font-size: 14px;
          }

          .-testLocation {
            font-size: 14px;
          }
        }
      }

      .accordion-collapse {
        border: 0;
        border-radius: 0;

        .accordion-body {
          padding: 0;
        }
      }
    }

    .accordion-button {
      font-family: $body-font, sans-serif;
    }

    //noinspection CssNoGenericFontName
    .accordion-button::after {
      @media (max-width: 768px) {
        top: 0;
        margin-top: 0;
        left: 4px;
      }
      font-family: "Further-Iconset" !important;
      content: "\e92d";
      position: absolute;
      background-image: none;
      top: 50%;
      left: 0;
      margin-top: -13px;
      font-size: 26px;
      width: 26px;
      height: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: black;
    }

    .accordion-button:not(.collapsed) {
      color: $accentColor;
      box-shadow: rgba(0, 0, 0, 0.45) 0 0 20px -5px;

      .-icon {
      }

      .-labName {
      }

      &:after {
      }
    }

    .accordion-button:not(.collapsed)::after {
      background-image: none !important;
      transform: rotate(180deg);
    }

    .-selectedLab {
      margin: 20px 0;

      .accordion-header,
      .accordion-button:not(.collapsed) {
        .-testDetails {
          .-icon {
            filter: none;
          }
        }
      }
    }
  }

  .nav-pills {
    padding: 0;

    .nav-item {
      padding: 0 4px;
    }
  }
}

.-flightPlanHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0;
  height: 300px;
  background-size: cover;

  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgba(255, 255, 255, 0.5);
  }
}

.-testList {
  .nav-pills {
    padding: 0;

    .nav-item {
      padding: 0 4px;
    }
  }

  .-option {
    position: relative;
    display: flex;
    flex-direction: column;

    .-icon {
      position: relative;
      float: left;
      color: $accentColor;
      font-size: 20px;
    }

    .-infoContainer {
      position: relative;
      float: left;
      font-size: 12px;
      color: #aaa;
      padding-left: 4px;

      b {
        color: black;
      }
    }

    .-timespan {
      position: relative;
      float: left;
      font-size: 14px;
      margin: 10px 0;
    }

    .-priceSpan {
      font-size: 12px;
    }

    &.-mandatoryTest {
      &:after {
        content: "";
        position: absolute;
        top: 4px;
        right: 8px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: red;
      }
    }
  }
}

.-contentContainer > .accordion-body {
  padding: 0;
}

.-contentContainer {
  padding-left: 0;
  padding-right: 0;
  border: 0;

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .divider {
    width: 200px;
  }

  .-contentBody {
    @media (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;
    }
    margin-top: 0 !important;
    margin-bottom: 12px !important;

    &.-left {
      @media (max-width: 768px) {
        padding-bottom: 30px;
      }
      position: relative;

      &:before {
        @media (max-width: 992px) {
          left: 0;
          width: 100%;
          height: 1px;
          top: auto;
        }
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        bottom: -12px;
        width: 1px;
        background-color: #eee;
        z-index: 1;
      }

      &:after {
        @media (max-width: 992px) {
          left: 50%;
          right: auto;
          margin-left: -5px;
        }
        content: "";
        position: absolute;
        right: -5px;
        margin-bottom: -5px;
        bottom: -12px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #eee;
      }
    }

    &.-right {
      @media (max-width: 768px) {
        padding-top: 30px;
      }
    }

    .-options {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-left: 0;
      margin-right: 0;
    }

    .-optionsInfo {
      flex-direction: row;
      align-items: center;
    }

    .-optionsInfo-col {
      flex-direction: column;
      justify-content: center;
    }

    .-optionsInfo, .-optionsInfo-col {
      padding: 10px 0;
      margin: 0 !important;
      display: flex;

      .-location {
        display: flex;
        padding: 0;
        font-size: 26px;
        font-weight: 600;
        margin-right: 6px;
        color: $accentColor;
      }

      .-labStatus {
        display: flex;
        flex-grow: 2;

        .-icon {
          font-size: 32px;
          margin: 0;

          &.-testDeparture {
            background-color: lighten($accentColor, 35%);
            border-right: 0 solid transparent;
            border-radius: 6px 0 0 6px;
          }
        }
      }

      .-requiredTestCount {
        display: flex;
        justify-content: center;
        height: 32px;

        .-badgeContainer {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .-reqTests {
            display: flex;
            flex-direction: row;

            .-text {
              display: flex;
              font-size: 14px;
              padding: 2px;
              justify-content: flex-end;
              align-items: center;
            }

            .-count {
              display: flex;
              align-items: center;
              font-size: 20px;
              padding: 0 2px;
            }
          }

          ul, li {
            list-style: none;
            margin: 0;
            padding: 0;
          }

          ul {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0 2px;

            li {
              margin: 2px;
              width: 14px;
              height: 14px;
              border-radius: 50%;
              background-color: $unsetTestColor;

              &.-set {
                background-color: $setTestColor;
              }
            }
          }
        }
      }
    }

    .-optionTabs {
      .-optionTabsNav, .bp3-tab-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid $primaryColor;

        .brief {
          min-width: 25%;
          padding: 5px;
        }

        .bp3-tab-indicator-wrapper {
          background-color: lighten($accentColor, 35%);

          &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -16px;
            border-style: solid;
            border-width: 16px 40px 0 0;
            border-color: lighten($accentColor, 35%) transparent transparent transparent;
          }

          .bp3-tab-indicator {
            display: none;
          }
        }

        .bp3-tab[aria-selected=true],
        .bp3-tab:not([aria-disabled=true]):hover {
          color: $accentColor;
        }

        .nav-item {
          width: 25%;

          .nav-link {
            position: relative;
            padding: 8px;
            color: black;
            font-size: 16px;
            height: 55px;

            &.active {
              background-color: lighten($accentColor, 35%);

              &:after {
                content: "";
                position: absolute;
                left: 0;
                bottom: -16px;
                border-style: solid;
                border-width: 16px 40px 0 0;
                border-color: lighten($accentColor, 35%) transparent transparent transparent;
              }
            }
          }
        }
      }

      p {
        font-size: 14px;
      }
    }

    .-locationText {
      position: relative;
      margin-bottom: 24px;

      div[class^="col-"] {
        padding-left: 0;
        padding-right: 0;
        margin: 0;
      }

      &:after {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        bottom: -16px;
        left: 50%;
        margin-left: -15px;
        border-style: solid;
        border-width: 16px 15px 0 15px;
        border-color: $accentColor transparent transparent transparent;
      }

      .-locationOpen {
        @media (max-width: 768px) {
          border: none;
          background-color: $accentColor;
          color: white;
          width: 98%;
          margin-left: 1%;
          border-radius: 4px;
          display: flex;
          flex-direction: row;
        }
        position: relative;
        float: left;
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        color: $accentColor;
        text-align: center;
        border-bottom: 1px solid $accentColor;

        .-inlineIcon {
          @media (max-width: 768px) {
            display: flex;
          }
          display: none;
          justify-content: center;
          align-items: center;
          min-width: 50px;
          width: 50px;
          font-size: 32px;
        }

        .-locationLabel {
          @media (max-width: 768px) {
            justify-content: flex-start;
            text-align: left;
          }
          display: flex;
          justify-content: center;
          flex-grow: 2;
          padding-top: 16px;
          padding-bottom: 16px;
        }

        &.-origin {
        }

        &.-destination {
        }
      }
    }

    .-messageBoxBig {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: calc(100% - 80px);
      margin: 0 !important;

      .-icon {
        font-size: 120px;
        color: rgba(0, 0, 0, 0.1);
      }
    }

    .-messageBoxCentered {
      padding: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &.-alert {
        .-icon {
          color: $alertColor;
        }
      }

      .-messageContainer {
        font-size: 16px;
      }
    }
  }
}

.-tests-origin-clinics {
  padding: 0;
}

.-footerNav {
  margin-bottom: 60px;
  flex-grow: 2;

  .row {
  }
}

.-paymentSummary {
  .-payLater, .-payNow {
    position: relative;
    float: left;
    width: 100%;
    margin-bottom: 20px;

    .-selectedTest {
      margin: 0;
      width: 100%;
    }

    .-messageBox {
      .-messageContainer {
        font-weight: 700;
        font-size: 16px;
      }

      .-icon {
        color: black;
      }
    }
  }
}


/// filters
.-filters {
  .col-md-12 {
    margin: 0;
    padding: 0;
  }

  .-filterRow {
    margin-bottom: 10px;

    .-filterTitle {
      margin-bottom: 10px;
      color: #aaa;
    }

    .nav {
      @media (max-width: 768px) {
      }
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-evenly;
      border-bottom: 1px solid $border-lightColor;

      .nav-item {
        padding: 0;
        margin: 0;
        flex-grow: 1;
        width: 16.6666%;
        @media (max-width: 768px) {
        }

        &:first-of-type {
          .nav-link {
            @media (max-width: 768px) {
            }
          }
        }

        &:last-of-type {
          .nav-link {
            @media (max-width: 768px) {
            }
          }
        }

        &:first-of-type {
          .nav-link {
          }
        }

        .nav-link {
          @media (max-width: 768px) {
            font-size: 12px;
          }
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          padding: 4px;
          color: $accentColor;
          text-align: center;
          border-style: solid;
          border-color: transparent;
          border-width: 0 0 4px 0;
          border-radius: 0;
          cursor: pointer;

          &.active {
            color: $primaryColor;
            background-color: transparent;
            border-color: $accentColor;
          }
        }
      }
    }
  }
}

.-title {
  height: 100px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;

  .-sectionTitle {
    font-size: 24px;
  }

  .container,
  .row {
    display: flex;
    align-items: flex-end;
  }
}

.-legalPages {
  padding-bottom: 50px;
}

.-faq {
  background-color: #f3f3f8;
  padding-top: 150px;
  padding-bottom: 150px;

  &.-singleClinic {
    background-color: lighten($accentColor, 45%);
    padding-top: 40px;
    padding-bottom: 40px;
  }

  h2.-faqTitle {
    font-size: 26px;
    padding-bottom: 50px;
  }

  .title {
    font-size: 20px;
    margin: 0;
    padding-bottom: 20px;
  }

  .nav-pills {
    justify-content: center;
    margin-bottom: 30px;

    .nav-item {
      @media (max-width: 768px) {
        width: 100%;
      }

      .nav-link {
        @media (max-width: 768px) {
          width: 100%;
        }
        color: black;
        font-size: 18px;
        font-family: $body-font, sans-serif !important;

        &.active {
          color: white;
          background-color: $accentColor;
        }
      }
    }
  }

  .accordion-item {
    padding: 0;
    margin: 12px 0;
    border: 0;
    background-color: transparent;

    &:last-of-type {
      border: 0;
    }

    .accordion-button {
      border: 0;
      padding: 12px;
      font-size: 16px;
      font-family: $body-font, sans-serif !important;

      &:focus {
      }

      &:not(.collapsed) {
        background-color: lighten($accentColor, 42%);
        color: $primaryColor;
        box-shadow: none;
      }
    }

    .accordion-body {
      padding: 12px;
      background-color: transparent;

      p {
        padding: 6px 6px 6px 6px;
        font-size: 16px;
      }
    }

    .accordion-collapse {
      border: 0;
    }
  }
}


.-steps {
  .progressBar {
    position: relative;
    width: 100%;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 20px;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      width: 100%;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.3);
      z-index: 0;
    }

    li {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &.-active {
        .stepNumber {
          width: 30px;
          height: 30px;
          background-color: rgba(255, 255, 255, 1);
        }

        .stepLabel {
        }
      }

      .stepLabel {
        position: absolute;
        top: -34px;
        display: flex;
        font-size: 16px;
      }

      .stepNumber {
        font-size: 22px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.3);
        box-shadow: 0 0 50px -25px rgb(0 0 0 / 30%);
        backdrop-filter: blur(5px);
        z-index: 10;
      }
    }
  }
}

$indicator-height: 0;
.progressBarMainContainer {
  padding-top: 30px;

  .progressBarMain {
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      position: relative;
      width: 25%;
      padding-bottom: 10px;
      border-bottom-width: $indicator-height;
      border-bottom-style: solid;
      border-bottom-color: transparent;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.3);

      &:before {
        content: "";
        position: absolute;
        height: 1px;
        top: 16px;
        left: 46px;
        right: -10px;
        background-color: rgba(0, 0, 0, 0.3);
      }

      &:last-child:after, &:last-child:before {
        display: none;
      }

      &.-active {
        color: $accentColor;
        font-weight: 700;
      }

      .-icon {
        padding-left: 12px;
        font-size: 32px;
        z-index: 1;
      }

      .stepNumber {
        display: none;
        padding: 6px 3px 6px 6px;
      }

      .stepLabel {
        @media (max-width: 768px) {
          display: none;
        }
        display: flex;
        flex-grow: 2;
        padding: 6px 6px 6px 16px;
        margin: 0;
      }
    }
  }
}

.-backButton {
  display: flex;
  flex-direction: row;
  align-items: center;

  .-backIcon {
    font-size: 22px;
    transform: rotate(-90deg);
  }
}

.-headerArea {
  background-color: lighten($primaryColor, 40%);
}

.-finalInfoPane {
  &.-successMessage, &.-failMessage {
    padding: 4px;

    .-message {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .-title {
      font-size: 20px;
      justify-content: flex-start;
      align-items: center;
    }

    .-iconPane {
      display: flex;
      justify-content: center;
      align-items: flex-start;

      .-icon {
        font-size: 120px;
      }
    }
  }

  &.-successMessage {
    .-icon {
      color: $confirmColor;
    }
  }

  &.-failMessage {
    .-icon {
      color: $exceptional-color;
    }
  }

  .-bookingDetails {
    .-labInfo {
      border-bottom: 1px solid $border-lightColor;
      padding: 4px 0;
    }
  }

  .-voucherCode {
    font-size: 16px;
    padding: 4px 0;

    .-code {
      color: $accentColor;
      font-size: 26px;
    }
  }

}

.-singleLab {
  .-backButton {
    height: 60px;
    text-decoration: none;
    color: black;
    cursor: pointer;

    .-backIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      font-size: 22px;
    }

    p {
      margin: 0;
      font-size: 16px;
    }
  }

  .-singleLab-details {
    font-size: 16px;

    div[class^="col-"] {
    }

    h1 {
      font-size: 26px;
      margin: 0;
    }

    b {
      font-size: 12px;
      color: #808080;
    }

    p {
      margin: 0;
      font-size: 14px;
    }

    .-labAddressContainer {
    }

    .-labInfo-hours {
      white-space: pre-wrap;
      border-bottom: 1px solid $border-lightColor;
    }

    .-labImages, .-mapContainer {
      border: 6px solid white;
      border-radius: 12px;
      overflow: hidden;
    }

    .-labImages {
      height: 250px;
    }

    .-mapContainer {
      position: relative;

      .-mapImageLink {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .-labTags {
      padding: 12px;
    }
  }

  .-availableTests {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .-message {
    font-size: 16px;
    line-height: 16px;
    padding: 6px;
    margin: 0 !important;
  }

  .-testList {
    justify-content: center;

    &.-selected {
      background-color: rgba(0, 0, 0, 0.05);
      padding: 4px 8px;
      border-radius: 4px;
    }

    .bp3-tab-panel {
      margin-top: 16px;
    }

    .bp3-tab-list {
      background-color: rgba(0, 0, 0, 0.05);
      padding: 4px 8px;
      border-radius: 4px;
      flex-wrap: wrap;
    }

    .bp3-tab[aria-selected="true"] {
      border-radius: 0;
      box-shadow: none;
      margin-right: 8px;

      button {
        background-color: $primaryColor;
        color: $activeTextColor;
      }
    }

    .bp3-tab-list > *:not(:last-child) {
      margin-right: 8px;
    }

    .bp3-tab {
      @media (min-width: 768px) {
        width: auto;
      }
      width: 100%;

      .btn {
        width: 100%;
      }
    }

    .nav {
      padding: 10px 0;
    }

    .btn {
      margin-top: 4px;
      margin-bottom: 4px;
    }

    .-selectedTestRow {
      display: flex;
      flex-direction: row;

      .-resetTest {
        display: flex;
        justify-content: center;
        align-items: center;

        .-icon {
          font-size: 24px;
          margin-left: -8px;
        }
      }

      .-selectedTestName {
        display: none;
        justify-content: center;
        align-items: center;
        flex-grow: 2;
        font-size: 16px;
        color: white;
        background-color: $accentColor;
        height: 40px;
        border-radius: 4px;
        padding: .375rem .75rem;
        margin: 4px;
        font-weight: 700;
      }
    }
  }

  .-mapContainer {
    height: 250px;
    padding: 0 !important;
  }

  .-tempMapPlaceholder {
    background-color: #eee;
  }

  /// custom styles for the booking details
  .-bookingInfo {
    .-testDate, .-timeFrame {
    }

    .-bookButton {
    }
  }

  .-labName {
    display: flex;
    flex-direction: column;
    padding: 20px 8px;

    h1 {
      display: flex;
      flex-grow: 2;
      align-items: flex-end;
      padding: 0;
    }
  }

  .-labImages {
    display: flex;
    flex-direction: column;
    padding: 0 !important;

    .-labImage {
      height: 250px;
      background-image: url("../img/lab-placeholder.jpg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .-map {
      display: none;
      width: 120px;
      height: 120px;
    }
  }

  .-footerSpacer {
    height: 100px;
  }

  .-mText {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .-tag {
      @media (max-width: 768px) {
        width: 50%;
      }
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 20%;
      text-align: center;
      padding: 0 4px;

      img {
        width: 48px;
        height: 48px;
      }
    }
  }

  .-testSelection {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .-labInfoContainer {
    padding-top: 20px;
    padding-bottom: 20px;

    .-labInfoCol {
    }
  }

  .-generalTags {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .-healthOrgLink {
    margin: 1rem;
  }

  .-passiveClinicInfo {
    padding-top: 20px;
    padding-bottom: 20px;

    h2 {
      font-size: 20px;
      padding-bottom: 20px;
      padding-top: 20px;
      font-weight: 400;
    }

    h3 {
      padding-bottom: 20px;
      padding-top: 20px;
      font-size: 18px;
      font-weight: 400;
    }

    p {
      padding: 10px;
      font-size: 14px;
    }

    li {
      padding: 5px;
    }

    @media (min-width: 1400px) {
      max-width: 910px;
    }
  }
}

.-allClinics {

  .select-container {
    select {
      color: $accentColor;
      font-weight: 700;
      font-size: 16px;
    }
  }

  .custom-dropdown {
    color: $accentColor;
    font-weight: 700;
  }

  .-header {
    padding: 100px 0;
    margin: 0;
    background: url(../img/header-all-clinics.jpg) center no-repeat;
    background-size: cover;

    .-pageTitle {
      h1 {
        font-size: 26px;
        color: white;
      }
    }
  }

  .-location {
    padding-bottom: 40px;
    padding-top: 40px;

    .title {
      color: #555;
      padding: 6px 12px;
    }
  }

  .-steps {
    padding: 16px 0;
    justify-content: center;
    display: none;

    .steps-landscape {
      @media (max-width: 768px) {
        display: none;
      }
      width: 720px;
    }

    .steps-portrait {
      @media (max-width: 768px) {
        display: block;
      }
      width: 270px;
      display: none;
    }
  }

  .-infoText {
    padding-top: 8px;
    padding-bottom: 0;
    font-size: 14px;
    color: black;
    display: flex;
    flex-direction: row;

    .-icon {
      position: relative;
      float: left;
      padding-right: 4px;
      padding-top: 4px;
      color: $primaryColor;
      font-size: 14px;
    }

    p {
      margin: 0;
      padding: 0;
    }
  }

  $lab-image-width: 33.3333%;
  $lab-image-height: 200px;

  .-tableContainer {
  }

  .-table {
    .-tile {
      margin: 12px 0;
    }

    .-tileContent {
      text-decoration: none;
      color: black;
      background-color: #ededed;
      height: 100%;
      margin: 0;
      border-radius: 6px;
      overflow: hidden;
      transition: all 0.5s ease;

      &:hover {
      }

      .-labInfoContainer {
        @media (min-width: 768px) {
          flex-direction: row;
          min-height: $lab-image-height;
        }
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;

        .-labImages {
          @media (min-width: 768px) {
          }
          display: flex;
          flex-direction: column;
          padding: 0;
          min-height: $lab-image-height;

          .-labImage {
            min-height: $lab-image-height;
            height: 100%;
            background-image: url("../img/lab-placeholder.jpg");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
          }

          .-map {
            display: none;
            width: 120px;
            height: 120px;
          }
        }

        .-labInfo {
          display: flex;
          flex-direction: column;
          flex-grow: 2;
          padding: 8px;
          @media (min-width: 768px) {
          }

          .cell {
            padding: 4px 0;
          }

          .label {
            position: relative;
            width: 100%;
            font-size: 12px;
            color: #808080;
            font-weight: 700;
          }

          .-labName {
            padding-top: 0;
            font-size: 18px;
            font-weight: 600;
            color: $accentColor;
            float: left;
          }

          .-labAddress, .-labHours {
            font-size: 14px;
          }

          .-labAddress {
            flex-grow: 2;
          }

          .-labHours {
            flex-direction: column;

            .value {
              white-space: pre-wrap;
              margin: 0;
            }
          }
        }
      }

      .-actionButton {
        display: flex;
        padding: 8px !important;
        justify-content: flex-end;
        align-items: flex-end !important;

        .btn {
          transition: all 0.5s ease;

          &:hover {
            @extend .-action-shadow;
          }
        }
      }

      .cell {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }
    }

    .row {
    }
  }

  .-regionButton {
    margin-bottom: 12px;

    .btn {
      width: 100%;
      transition: all 0.5s ease;

      &:hover {
        @extend .-action-shadow;
      }
    }

    @media (max-width: 1200px) {
      width: 100%;
      display: flex;
      .col-3 {
        display: inline-table !important;
      }
    }
  }
}

.-filters {
  padding-top: 40px;
  padding-bottom: 40px;

  .-testBtnCol {
    display: flex;

    .-testBtn {
      width: calc(70%);
      max-width: 200px;
      padding: 5px;
      margin-top: 2px;
    }
  }

  .-filterContainer {
    &.-tags {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;

      .-filterCol {
        position: relative;
        float: left;
        display: flex;
        flex-direction: column;
        width: calc(20% - 8px);
        margin: 4px;
        padding: 4px;
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        @media (max-width: 992px) {
          width: calc(33.3333% - 8px);
        }
        @media (max-width: 768px) {
          width: calc(100% - 8px);
        }
      }
    }

    &.-tests {
      .-filterCol {
        width: calc(100% - 8px);
        margin: 4px;

        .tag-filter {
          @extend .custom-select;

          li {
            list-style: none;
          }

          .tag-filter-icon {
            height: 25px;
          }

          .tag-filter-input {
            min-height: 20px;
          }
        }
      }
    }

    .-filterTitle {
      font-size: 16px;
      padding-bottom: 4px;
      padding-top: 4px;
      font-weight: 700;
      color: $accentColor;
    }

    .-filterCol {
      .-filterItem {
        margin: 4px 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        .-filterLabel {
          position: relative;
          padding: 0 4px;
        }

        .-toggle {
          position: relative;
          border-radius: 10px;
          min-width: 34px;
          width: 34px;
          height: 20px;
          margin: 2px;
          background-color: #ccc;
          cursor: pointer;

          .switch {
            position: absolute;
            background-color: white;
            left: 0;
            border: 3px solid #ccc;
            width: 20px;
            height: 20px;
            border-radius: 16px;
          }

          &.-active {
            background-color: $accentColor;
            color: white;

            .switch {
              left: unset;
              right: 0;
              border: 3px solid $accentColor;
            }
          }
        }
      }
    }
  }
}

.-labTestSelection {
  background-color: darken(white, 5%);
  padding: 8px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .-labTags-tests {
    display: flex;
    flex-direction: column;

    .-labTagContainer {
      width: 100% !important;
      display: flex;
      flex-direction: row !important;
      flex-flow: wrap;

      .-tag {
        position: relative;
        float: left;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        background-color: lighten($accentColor, 35%);
        color: $accentColor;
        border-radius: 4px;
        padding: 4px 6px;
        margin: 2px;
        width: 100%;

        .-icon {
          font-size: 22px;
          display: none;
        }

        .-tagName {
          font-size: 14px;
          text-align: center;
          align-items: center;
          justify-content: center;
          color: $accentColor;
        }
      }

      .-clickable {
        cursor: pointer;
      }

      .-clickable:hover {
        background-color: $accentColor;

        .-tagName {
          color: $activeTextColor;
        }
      }
    }
  }

  .cell {
    width: 100%;
    padding: 2px;

    .btn {
      width: 100%;
    }
  }
}

.-labTags-tags {
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  padding: 0;

  &.-singleLab {
    border-top: none;

    .-labTagContainer {
      width: 50%;
      @media (min-width: 992px) {
        width: 20%;
      }
    }
  }
}

.-labTags {
  display: flex;
  flex-direction: column;
  padding: 0;

  &.-singleLab {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.-notes {
  margin: 30px 0;

  .container {
    padding-top: 30px;
    border-top: 1px solid $border-lightColor;

    .col-md-3 {
      margin-bottom: 20px;
    }
  }
}

/// ---- lab tags ----
.-labTagContainer {
  display: flex;
  flex-direction: row !important;
  flex-flow: wrap;

  .-tag {
    position: relative;
    float: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    color: $accentColor;
    border-radius: 4px;
    margin: 0 8px 4px 0;
    width: 100%;

    .-icon {
      font-size: 22px;
    }

    .-tagName {
      display: flex;
      flex-grow: 2;
      line-height: 14px;
      padding: 2px 4px 2px 0;
      font-size: 12px;
      color: #4c4d4c;
    }
  }

  &.tag-official {
    .-tag {
      background-color: lighten($confirmColor, 20%);
    }
  }
}

.-ad {
  margin-bottom: 20px;
}

.contact-form {
  margin-bottom: 2rem;

  .form-nav {
    margin-top: 2rem;
    margin-bottom: 2rem;

    .form-nav-header {
      font-size: 1rem;
    }
  }

  .contact-thanks {
    min-height: 20vh;
    padding-top: 20px;
  }
}

