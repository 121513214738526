// App Promotion

.-appPromotion {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 80vh;

  .-bgColor {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 67%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(233, 233, 242, 1) 100%);
    z-index: -1;
  }

  .-appPromotion-jumbotron {
    height: 100%;
    padding: 50px 0;
    overflow: hidden;

    .-messages {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .-appPromotion-subText {
        font-size: 18px;
        margin: 0;
      }

      .-storeBadges {
        display: flex;
        flex-direction: row;
        padding: 40px 0 0 0;
        @media (max-width: 991px) {
          justify-content: center;
        }

        .-storeBadge {
          margin-right: 10px;

          .-badge {
            height: 50px;
            position: relative;
            float: left;
          }
        }
      }
    }

    .-heroImageContainer {
      display: flex;
      align-items: flex-end;
      @media (max-width: 991px) {
        justify-content: center;
      }

      .-heroImage {
        @media (max-width: 991px) {
          width: 300px;
          height: auto;
        }
        width: auto;
        height: 600px;
      }
    }
  }
}

.-appScreenShots {
  @media (max-width: 768px) {
    min-height: auto;
    height: 300px;
  }
  min-height: 80vh;
  height: 800px;
  background: url(../img/app-screens-show.jpg) center no-repeat;
  background-size: cover;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.-appVideo {
  margin: 100px 0;

  .-appVideoContainer {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

    .video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
}

.-appDownloadBadges {
  .-storeBadges {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 0 100px 0;
    padding: 40px 0;

    .-storeBadge {
      margin-right: 10px;

      .-badge {
        height: 50px;
        position: relative;
        float: left;
      }
    }
  }
}
