// Stripe

.stripe-payment-form {
  align-self: center;
  box-shadow: none;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 8px;
  font-family: "Nunito Sans", sans-serif !important;
  input {
    font-size: $input-font-size;
    height: $input-height;
    width: $input-width;
    padding: $input-padding;
    border-radius: $input-border-radius;
    background-color: $input-bg-color;
    border-style: $input-border-style;
    border-width: $input-border-width;
    border-color: $input-border-color;
    font-family: $input-typeface;
  }

  button {
    border-radius: 4px;
    border: none;
    padding: 12px 16px;
    font-family: "Nunito Sans", sans-serif !important;
    font-size: 16px;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    width: 100%;
    margin-top: 10px;
    &.submit {
      background-color: $accentColor;
      color: #ffffff;
    }

    &.cancel {
    }

    &:hover {
      box-shadow: 0 5px 30px -10px $accentColor;
    }

    &:disabled {
      &:hover {
        box-shadow: none;
      }

      opacity: 0.7;
      cursor: default;
    }
  }
}
