// Overrides

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $header-font, sans-serif !important;
  font-weight: 400;
  margin: 0;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none !important;
}

p {
  margin: 0;
}

label,
.form-label {
  font-size: 12px;
  margin: 0;
}

.btn {
  font-family: $body-font, sans-serif !important;
  border-width: 2px;
}

.btn-primary {
  color: #fff;
  background-color: $primaryColor;
  border-color: $primaryColor;
}

.btn-primary:hover {
  color: #fff;
  background-color: $primaryColor;
  border-color: $primaryColor;
}

.btn-primary-header {
  background-color: $primaryColor;
  border-color: $primaryColor;
}

.btn-secondary-header {
  background-color: $secondaryColor;
  border-color: $secondaryColor;
}

.btn-navbar {
  color: #000000;
  margin-top: 12px;
  margin-right: 5px;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: lighten($primaryColor, 10%);
  border-color: lighten($primaryColor, 10%);
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: $primaryColor;
  border-color: #000;
}

.btn-outline-primary {
  color: $primaryColor;
  background-color: transparent;
  background-image: none;
  border-color: $primaryColor;
}

.btn-outline-primary:hover,
.btn-outline-primary:checked,
.btn-check:checked + .btn-outline-primary {
  color: #fff;
  background-color: $primaryColor;
  border-color: $primaryColor;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: $primaryColor;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: $primaryColor;
  border-color: $primaryColor;
  @extend .-action-shadow;
}

/// phone field override
.react-tel-input {
  .form-control {
    font-size: $input-font-size !important;
    height: $input-height !important;
    width: $input-width !important;
    padding: $input-padding !important;
    padding-left: 48px !important;
    border-radius: $input-border-radius !important;
    background-color: $input-bg-color !important;
    border-style: $input-border-style !important;
    border-width: $input-border-width !important;
    border-color: $input-border-color !important;
    font-family: $input-typeface !important;
  }

  .flag-dropdown {
    background-color: $input-bg-color !important;
    border-style: $input-border-style !important;
    border-width: $input-border-width !important;
    border-color: $input-border-color !important;
  }
}

// .react-datepicker override
.react-datepicker-popper {
  margin-top: 0 !important;
  z-index: 50;
}

.react-datepicker {
  border: 0 !important;
  @extend .-box-shadow;
}

.sc-edESPO {
  button {
    border: 0;

    svg {
      display: none;
    }

    //noinspection CssNoGenericFontName
    &:after {
      // content: "\f00d";
      content: "\e920";
      opacity: .8;
      font-family: "Further-Iconset" !important;
      line-height: 1;
      height: 1em;
      width: 1.23em;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      font-weight: 400;
      font-style: normal;
      text-align: center;
      font-size: 12px;
    }
  }
}

.cuZhGz {
  background-color: transparent !important;
}

.sc-cVAliH {
  z-index: 10;
}

/// form control override
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
}

/// semantic override
.ui.selection.dropdown {
  cursor: pointer;
  word-wrap: break-word;
  outline: 0;
  transform: rotateZ(0);
  background: none;
  padding: 0 !important;
  border: 0;
  border-radius: 0;
  display: flex;
  align-items: center;
}

.ui.selection.dropdown .menu {
  width: 100%;
  min-width: 100%;
  margin: 0;
}

.ui.selection.active.dropdown .menu,
.ui.selection.active.dropdown:hover .menu {
  border: 0;
  box-shadow: 0 30px 35px -15px rgb(0 0 0 / 15%);
}

.ui.selection.dropdown .menu > .item {
  border-top: 1px solid #eee;
  padding: 8px !important;
}

.ui.search.dropdown > .text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
  padding: $input-padding;
}

.ui.search.selection.dropdown > input.search {
  padding: $input-padding;
}

.ui.search.dropdown > input.search {
  font-size: $input-font-size;
  height: $input-height;
  width: $input-width;
  padding: $input-padding !important;
  border-radius: $input-border-radius;
  background-color: $input-bg-color !important;
  border-style: $input-border-style !important;
  border-width: $input-border-width !important;
  border-color: $input-border-color !important;
  font-family: $input-typeface;
}

.ui.selection.dropdown > .delete.icon,
.ui.selection.dropdown > .dropdown.icon,
.ui.selection.dropdown > .search.icon {
  top: auto;
}

.bp3-breadcrumbs {
  height: auto;

  li {
    padding: 4px 0;
  }
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #fff;
  background-color: $accentColor;
}

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
  width: 100%;
}

.nav-link {
  color: $accentColor;
}

.css-1pahdxg-control {
  max-height: 38px !important;
  height: 38px !important;
  min-height: 38px !important;
  width: 100% !important;
  font-size: 14px !important;
  font-family: "Nunito Sans", sans-serif !important;
  padding: 6px 36px 6px 12px !important;
  border-radius: 4px !important;
  background-color: #e9ecef !important;
  color: #6466AC !important;
  border-style: solid !important;
  border-width: 0 0 1px 0 !important;
  border-color: transparent transparent transparent transparent !important;
  box-shadow: none !important;
}

.css-1s2u09g-control {
  max-height: 38px !important;
  height: 38px !important;
  min-height: 38px !important;
  width: 100% !important;
  font-size: 14px !important;
  font-family: "Nunito Sans", sans-serif !important;
  padding: 6px 36px 6px 12px !important;
  border-radius: 4px !important;
  background-color: #e9ecef !important;
  color: #6466AC !important;
  border-style: solid !important;
  border-width: 0 0 1px 0 !important;
  border-color: transparent transparent transparent transparent !important;
  box-shadow: none !important;
}
.css-14dclt2-Input{
  margin: -20px!important;
}

.css-14el2xx-placeholder{
  color: #6466AC !important;
}

.mb-2{
  margin-bottom: 0!important;
}

.css-1okebmr-indicatorSeparator{
  width: 0!important;
}
