// Universal Components

//noinspection CssNoGenericFontName
.further-icon-base {
  font-family: "Further-Iconset" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

/// universal test info container
.-testInfoContainer {
  // border:1px solid $border-lightColor;
  background-color: rgba(0, 0, 0, 0.05);
  margin: 16px 0 0 0;
  padding: 4px;
  border-radius: 4px;

  .-messageBoxRow {
    margin-left: 0;
    margin-right: 0;
  }

  .-testInfo {
    margin-left: 0;
    margin-right: 0;

    .-testDetails {
      margin: 0;
    }
  }
}

/// universal selected group design
.-selectedTest {
  position: relative;
  width: calc(100% - 8px);
  float: left;
  padding: 0;
  margin: 4px;
  background-color: rgba(0, 0, 0, 0.05);
  // border: 1px solid $border-lightColor;
  overflow: hidden;
  border-radius: 4px;

  .-title {
    display: flex;
    height: auto;
    padding: 0;
    border: 0;
    background-color: $confirmColor;
    border-bottom: 1px solid $border-lightColor;
    justify-content: center;

    &:before {
      @extend .further-icon-base;
      display: flex;
      margin: 0 8px 0 0;
      padding: 4px;
      font-size: 28px;
      width: 36px;
      min-width: 36px;
      min-height: 36px;
      color: black;
      position: relative;
      float: left;
      border-right: 1px solid $border-lightColor;
    }

    &.-warning {
      &:before {
        content: "\e928";
      }

      background-color: $warning-color;
    }

    &.-online {
      &:before {
        content: "\e917";
      }

      background-color: $confirmColor;
    }

    &.-offline {
      &:before {
        content: "\e928";
      }

      background-color: #0dcaf0;
    }

    .-icon {
      display: flex;
      margin: 0 8px 0 0;
      padding: 4px;
      font-size: 28px;
      width: 36px;
      min-width: 36px;
      min-height: 36px;
      color: black;
      position: relative;
      float: left;
      border-right: 1px solid $border-lightColor;
    }

    .-text {
      display: flex;
      flex-grow: 2;
      align-items: center;
      padding-top: 6px;
      padding-bottom: 6px;
      font-size: 16px;
      font-weight: 700;
    }
  }

  .-testDate, .-testTimeframe {
    position: relative;
    float: left;

    .-title {
      padding: 0;
      background-color: transparent;
    }
  }

  .-labName {
    width: 100%;
  }

  .-testDate, .-testTimeframe {
    width: 50%;
  }

  .-changeButton {
    @media (max-width: 768px) {
      flex-direction: column;
    }
    position: relative;
    width: 100%;
    float: left;
    // margin-top: 8px;
    display: flex;
    align-items: center;
    padding: 8px;
    border-top: 1px solid $border-lightColor;

    .btn {
      margin: 0 6px;
    }
  }

  .-container {
    position: relative;
    float: left;
    width: 33.333%;
    display: flex;
    flex-direction: column;
    padding: 8px;
    font-size: 16px;

    .-label {
      font-weight: 600;
    }
  }
}

/// universal booking container
.-bookingInfo {
  padding: 4px;
  display: flex;
  justify-content: center;
  margin-left: 0;
  margin-right: 0;

  .-testDate, .-timeFrame {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding: 0;

    .-sectionTitle {
      font-size: 14px;
      padding: 4px 8px;
      width: 100%;
      height: 32px;
      text-align: center;
      border-bottom: 1px solid $border-lightColor;
    }
  }

  .-testDate {
    // @media (max-width: 1200px) {
    //   border:0;
    // }
    // border-right:1px solid $border-lightColor;
    .DayPicker {
      border-radius: 4px;
      width: 100%;
      font-size: 16px;
      // border:1px solid red;
      .DayPicker-wrapper {
        position: relative;
        flex-direction: row;
        padding-bottom: 0;
        user-select: none;
        // width:300px;
        .DayPicker-NavBar {
          .DayPicker-NavButton {
            position: absolute;
            top: 4px;
            right: 8px;
            left: auto;
            display: inline-block;
            margin-top: 2px;
            width: 1.25em;
            height: 1.25em;
            background-position: center;
            background-size: 50%;
            background-repeat: no-repeat;
            color: #8B9898;
            cursor: pointer;
          }
        }

        .DayPicker-Months {
          .DayPicker-Month {
            width: 100%;
            display: table;
            margin: 0;
            border-spacing: 0;
            border-collapse: collapse;
            user-select: none;

            .DayPicker-Caption {
              display: table-caption;
              margin-bottom: 0;
              padding: 4px 8px;
              text-align: left;
              background-color: rgba(0, 0, 0, 0.1);
              font-size: 14px;
              height: 32px;

              div {
                font-size: 16px;
              }
            }

            .DayPicker-Weekdays {
              display: table-header-group;
              margin-top: 0;
            }

            .DayPicker-WeekdaysRow {
              .DayPicker-Weekday {
                display: table-cell;
                padding: 6px;
                color: #555;
                text-align: center;
                font-size: 12px;
              }
            }

            .DayPicker-Body {
              .DayPicker-Week {
                .DayPicker-Day {
                  display: table-cell;
                  padding: 6px;
                  // border-radius: 50%;
                  vertical-align: middle;
                  text-align: center;
                  cursor: pointer;
                  border-radius: 0;
                  // border: 2px solid #f2f2f2;
                  &.DayPicker-Day--today {
                    color: black;
                    background-color: rgba(0, 0, 0, 0.05);
                    font-weight: 700;
                  }
                }
              }
            }
          }
        }
      }
    }

    .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
      background-color: $accentColor;
      color: white;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      background-color: $accentColor;
      color: white;
    }
  }

  .-timeFrame {
    display: flex;
    flex-direction: column;

    .-timeFrameOptions {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;

      label {
        margin: 0;
        width: 100%;
        font-size: 16px;
        line-height: 24px;
        border: 0;
        border-radius: 0;

        &:hover {
          color: white;
        }

        &.slot {
          padding: 5px;
        }

        &.half {
          &:first-of-type {
            border-radius: 0 !important;
            border-bottom: 0;
          }

          &:last-of-type {
            border-radius: 0 !important;
          }
        }

        &:after {
          content: "";
          flex: auto;
        }
      }
    }

    .-timeFrameOptions.-singleLab {
      label {
        &.slot {
          @media (max-width: 1200px) {
            width: 20%
          }
          width: 25%;
        }
      }
    }
  }

  .-bookButton {
    border-top: 1px solid $border-lightColor;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0 0 0;

    .btn {
      @media (max-width: 768px) {
        width: 100%;
      }
      display: flex;
      justify-content: center;
      background-color: $accentColor;
      border: 0;
      font-size: 18px;
      @extend .-action-shadow;
    }
  }
}

// universal test details
$td-label-font-size: 14px;
.-testDetails {
  @media (max-width: 768px) {
    flex-direction: column;
  }
  padding: 4px;

  .-name {
    &:before {
      content: "\e90c";
    }

    width: 100%;
    flex-direction: row;

    .-label {
      align-items: center;
    }
  }

  .-price, .-location, .-timeFrame {
    width: 33.333%;
    flex-direction: row;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }

  .-timeFrame {
    &:before {
      content: "\e901";
    }

    border-right: 0;
  }

  .-price {
    &:before {
      content: "\e90d";
    }

    .ccyController {
      display: flex;
      flex-direction: row;

      .-label {
        flex-grow: 0 !important;
      }

      select {
        height: 25px;
        padding: 0 16px 0 0;
        border-radius: 2px;
        font-size: $td-label-font-size;
        margin-top: -4px;
        margin-left: 7px;
        border: 1px solid $border-lightColor;
        background-color: #ffffff;
      }
    }
  }

  .-location {
    &:before {
      content: "\e914";
    }
  }

  .-name, .-price, .-location, .-timeFrame {
    @media (max-width: 768px) {
      width: 100%;
      flex-direction: row;
      border-right: 0;
    }
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    display: flex;
    font-size: $td-label-font-size;
    padding: 4px;
    border-bottom: 1px solid $border-lightColor;

    &:before {
      @extend .further-icon-base;
      font-size: 24px;
      color: $accentColor;
    }

    .-icon {
      min-width: auto;
      min-height: auto;
      font-size: 24px;
      color: $accentColor;
    }

    p, .-label {
      display: flex;
      flex-grow: 2;
      align-items: flex-start;
      position: relative;
      margin: 0;
      padding-top: 4px;
      font-size: $td-label-font-size;
    }
  }

  .-changeButton {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 10px;
  }
}

// universal lab info details
$labInfoDetails-title-font-size: 12px;
$labInfoDetails-title-color: #808080;
.-labInfoDetails {
  margin: 0;
  padding: 4px;
  position: relative;
  float: left;
  width: 100%;

  .-dateTimeContainer {
    padding-right: 0;
    padding-left: 0;

    .-labInfoCol {
      width: 50%;
      float: left;
      border-bottom: 0;
    }
  }

  .-labInfoCol {
    border-bottom: 1px solid $border-lightColor;
    padding: 2px;

    .-labInfoDetails-title, .-labInfoDetails-label {
      padding: 2px;
      margin: 0;
      display: flex;
      align-items: center;
    }

    .-labInfoDetails-title {
      font-size: $labInfoDetails-title-font-size;
      color: $labInfoDetails-title-color;
    }

    &:last-of-type {
      border: 0;
    }
  }

  &.-iconified {
    .-labInfoDetails-title {
      display: none;
    }

    .-labInfo-testType,
    .-labInfo-testName,
    .-labInfo-clinicName,
    .-labInfo-name,
    .-labInfo-date,
    .-labInfo-time,
    .-labInfo-price,
    .-labInfo-email,
    .-labInfo-contact,
    .-labInfo-phone,
    .-labInfo-phone2,
    .-labInfo-web {
      &:before {
        @extend .further-icon-base;
        position: relative;
        float: left;
        width: 28px;
        font-size: 22px;
        color: $accentColor;
      }
    }

    .-labInfo-testType:before {
      content: "\e90c";
    }

    .-labInfo-testName:before {
      content: "\e90c";
    }

    .-labInfo-clinicName:before {
      content: "\e931";
    }

    .-labInfo-name:before {
      content: "\e931";
    }

    .-labInfo-date:before {
      content: "\e91d";
    }

    .-labInfo-time:before {
      content: "\e901";
    }

    .-labInfo-price:before {
      content: "\e90d";
    }

    .-labInfo-email:before {
      content: "\e935";
    }

    .-labInfo-contact:before {
      content: "\e932";
    }

    .-labInfo-phone:before {
      content: "\e933";
    }

    .-labInfo-phone2:before {
      content: "\e933";
    }

    .-labInfo-web:before {
      content: "\e925";
    }
  }
}

// universal message box with before elements
.-messageBox {
  padding: 8px;
  margin-top: 0 !important;
  margin-bottom: 1rem !important;
  display: flex;
  flex-direction: row;

  p, ul {
    padding-bottom: 0 !important;
    margin-bottom: 0.5rem !important;
  }

  &.-highlight {
    background-color: lighten($alertColor, 43%);
  }

  &:before {
    @extend .further-icon-base;
    font-size: 22px;
    min-width: 22px;
    width: 22px;
    height: 22px;
    margin: 0 8px 0 0;
  }

  &.-info {
    &:before {
      content: "\e905";
      color: $accentColor;
    }
  }

  &.-alert {
    &:before {
      content: "\e90e";
      color: $alertColor;
    }
  }

  &.-exceptional {
    &:before {
      content: "\e921";
      color: $exceptional-color;
    }
    .-red-title {
      color: $exceptional-color;
    }
  }

  .-locationOpen {
    position: relative;
    float: left;
    width: 100%;
    font-size: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-weight: 400;

    &.-origin {
      text-align: left;
    }

    &.-destination {
      text-align: right;
    }
  }

  .-messageContainer {
    @media (max-width: 768px) {
      font-size: 12px;
    }
    display: flex;
    align-items: center;
    position: relative;
    float: left;
    font-size: 14px;
    padding-bottom: 0;
    font-weight: 400;

    a {
      padding: 0 4px;
    }

    p {
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }

    strong {
      font-weight: 600;
    }
  }
}
